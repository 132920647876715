.history-detail-table {
    margin-top: 10px;
}
.history-detail-table td {
    color: white;
    font-size: 12px;
    width: 84px;
    white-space: nowrap;
    text-align: center;
    padding: 4px 0 !important;
    font-weight: 700;
}
